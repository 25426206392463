var urlVars = {}
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
  urlVars[key] = decodeURIComponent(value).replace(/\+/g, ' ')
})

if (urlVars.failure) {
  var alertDiv = document.createElement('div')
  alertDiv.className = 'alert alert-danger'
  alertDiv.textContent = urlVars.failure
  var loginForm = document.getElementsByTagName('form')[0]
  loginForm.insertBefore(alertDiv, loginForm.firstChild)
}
